import { VuexModule, Module, Mutation, Action, config } from "vuex-module-decorators";
import { companyApi } from "@/api/companies";
import {
  Company,
  CompanyDetail,
  CompanyListItem,
  CompanyLogo,
  CompanyLogoDetail,
  DepartmentListItem,
  Warehouse,
  WarehouseCreate,
  WarehouseDetail,
  WarehouseListItem,
  WarehouseUpdate,
} from "@/types/companies";
import { assertNotNull, safeResolve } from "../utils";
import { uiStore } from "@/store";
import { Cache, CacheKey, CacheValue } from "@/types/utils";
import { Base64String } from "@/types/common";

function arrayBufferToBase64(buffer) {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

config.rawError = true;

type CompanyCache = Cache<Company["id"], CompanyDetail>;
type WarehouseCache = Cache<Warehouse["id"], WarehouseDetail>;

@Module({ name: "company", namespaced: true })
export default class CompanyModule extends VuexModule {
  companyCache: CompanyCache = {};
  warehouseCache: WarehouseCache = {};

  warehouses: WarehouseListItem[] | null = null;
  companies: CompanyListItem[] | null = null;
  departments: DepartmentListItem[] | null = null;

  @Mutation
  setCompanyCacheForId(payload: {
    id: CacheKey<CompanyCache>;
    data: CacheValue<CompanyCache>;
  }) {
    this.companyCache[payload.id] = payload.data;
  }

  @Action
  async getCompany(payload: { id: Company["id"] }) {
    const companyId = payload.id;
    const companyData = this.companyCache[companyId] ?? null;
    if (companyData === null) {
      const data = await safeResolve(companyApi.getCompany(companyId));
      this.setCompanyCacheForId({ id: companyId, data });
    }
    return assertNotNull(this.companyCache[companyId]);
  }

  @Action
  async getCompanyLogo(id: CompanyLogo["company"]["id"]) {
    const company = await this.getCompany({ id });

    const data = await safeResolve(companyApi.getCompanyLogo(company.logo_url));

    return {
      company_id: id,
      company_name: company.name,
      image_data: arrayBufferToBase64(data) as Base64String,
      file_type: company.logo_url.endsWith("png") ? "png" : "jpeg",
    } as CompanyLogoDetail;
  }

  @Mutation
  setWarehouseCacheForId(payload: {
    id: CacheKey<WarehouseCache>;
    data: CacheValue<WarehouseCache>;
  }) {
    this.warehouseCache[payload.id] = payload.data;
  }

  @Action
  async getWarehouse(payload: { id: Warehouse["id"] }) {
    const warehouseId = payload.id;
    const warehouseData = this.warehouseCache[warehouseId] ?? null;
    if (warehouseData === null) {
      const data = await safeResolve(companyApi.getWarehouse(warehouseId));
      this.setWarehouseCacheForId({ id: warehouseId, data });
    }
    return assertNotNull(this.warehouseCache[warehouseId]);
  }

  @Mutation
  setWarehouses(data: WarehouseListItem[] | null) {
    this.warehouses = data;
  }

  @Mutation
  setCompanies(data: CompanyListItem[] | null) {
    this.companies = data;
  }

  @Mutation
  setDepartments(data: DepartmentListItem[] | null) {
    this.departments = data;
  }

  @Action
  async listWarehouses(cache = true) {
    if (this.warehouses === null || !cache) {
      const data = await safeResolve(companyApi.listWarehouses());
      this.setWarehouses(data);
    }
    return assertNotNull(this.warehouses);
  }

  @Action
  async deleteWarehouse(payload: Warehouse["id"]) {
    const data = await safeResolve(companyApi.deleteWarehouse(payload));
    uiStore.addNotification({ content: data.detail, color: "success" });
  }

  @Action
  async updateWarehouse(payload: { id: Warehouse["id"]; data: WarehouseUpdate }) {
    const { data, errors } = await safeResolve(
      companyApi.updateWarehouse(payload.id, payload.data),
      true,
    );

    if (errors) {
      return errors;
    }

    this.setWarehouses(null);
    this.setWarehouseCacheForId({ id: payload.id, data: null });
    if (data) {
      uiStore.addNotification({ content: data.detail, color: "success" });
    }
  }

  @Action
  async createWarehouse(payload: WarehouseCreate) {
    const promise = companyApi.createWarehouse(payload);
    const { data, errors } = await safeResolve(promise, true);

    if (errors) {
      return errors;
    }

    await this.listWarehouses(false);
    if (data) {
      uiStore.addNotification({ content: data.detail, color: "success" });
    }
  }

  @Action
  async listCompanies(cache = true) {
    if (this.companies === null || !cache) {
      const data = await safeResolve(companyApi.listCompanies());
      this.setCompanies(data);
    }
    return assertNotNull(this.companies);
  }

  @Action
  async listDepartments(cache = true) {
    if (this.departments === null || !cache) {
      const data = await safeResolve(companyApi.listDepartments());
      this.setDepartments(data);
    }
    return assertNotNull(this.departments);
  }

  @Mutation
  setCompanyCache(payload: CompanyCache) {
    this.companyCache = payload;
  }

  @Mutation
  setWarehouseCache(payload: WarehouseCache) {
    this.warehouseCache = payload;
  }

  @Action
  clearCache() {
    this.setWarehouses(null);
    this.setCompanyCache({});
    this.setWarehouseCache({});
  }
}
